.wizard,
.tabcontrol
  display block
  width 100%
  overflow hidden
.wizard a,
.tabcontrol a
  outline 0
.wizard ul,
.tabcontrol ul
  list-style none !important
  padding 0
  margin 0
.wizard ul > li,
.tabcontrol ul > li
  display block
  padding 0
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info
  position absolute
  left -999em
.wizard > .content > .title,
.tabcontrol > .content > .title
  position absolute
  left -999em
.wizard
  & > .steps
    position relative
    display block
    width 100%

    .number
      font-weight: bold

    & > ul
      & > li
        width 25%
  &.vertical
    & > .steps
      display inline
      float left
      width 30%
      & > ul
        & > li
          float none
          width 100%
    & > .content
      display inline
      float left
      margin 0
      width 65%
    & > .actions
      display inline
      float right
      margin 0 2.5%
      width 95%
      & > ul
        & > li
          margin 0 0 0 1em
  & > .content
    display block
    margin 0
    padding: 0
    min-height 15rem
    position relative
    width auto
    overflow: auto

    & > .body
      position absolute
      width 100%
      height auto
      &.current
        position: relative

      ul
        list-style disc !important
        & > li
          display list-item
      & > iframe
        border 0 none
        width 100%
        height 100%
  & > .actions
    position relative
    display block
    text-align: center
    width 100%
    & > ul
      display block
      & > li
        display: block
        width: 50%
        > a
          padding: 1rem

  // & > .loading
  //   .spinner

.wizard > .steps > ul > li,
.wizard > .actions > ul > li
  float left

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active
  display block
  width auto
  padding 1em 1em
  text-decoration none



.wizard
  &.-dark
    > .steps
      border-bottom: 1px solid $scheme['dark']['background']['lighter']
      li
        a
        a:hover
        a:active
          border-top: 1px solid $scheme['dark']['background']['lighter']
          border-right: 1px solid $scheme['dark']['background']['lighter']
          border-left: 1px solid $scheme['dark']['background']['lighter']
          color: $scheme['light']['color']['default']

        .number
          color: $scheme['light']['color']['medium']

    > .steps .disabled a,
    > .steps .disabled a:hover,
    > .steps .disabled a:active
      background: $scheme['dark']['background']['lighter']
      color: $scheme['light']['color']['medium']
      cursor default
    > .steps .current a,
    > .steps .current a:hover,
    > .steps .current a:active
      background: $scheme['dark']['background']['medium']
      cursor default
    > .steps .done a,
    > .steps .done a:hover,
    > .steps .done a:active
      background: $scheme['dark']['background']['light']
      color: $scheme['light']['color']['dark']

    > .steps .error a,
    > .steps .error a:hover,
    > .steps .error a:active
      background: $brand['danger']
      color #fff

    > .actions a,
    > .actions a:hover,
    > .actions a:active
      background: $scheme['dark']['background']['medium']
      background: linear-gradient(to bottom right, $scheme['dark']['background']['medium'], $scheme['dark']['background']['light'])
      display block
      text-decoration none
    > .actions .disabled a,
    > .actions .disabled a:hover,
    > .actions .disabled a:active
      cursor: default
      color: $scheme['light']['color']['medium']
      background: $scheme['dark']['background']['light']
      border-right: 1px solid $scheme['dark']['background']['lighter']


  &.-light
    > .steps
      border-bottom: 1px solid $scheme['light']['background']['lighter']
      li
        a
        a:hover
        a:active
          border-top: 1px solid $scheme['light']['background']['lighter']
          border-right: 1px solid $scheme['light']['background']['lighter']
          border-left: 1px solid $scheme['light']['background']['lighter']
          color: $scheme['light']['color']['default']

        .number
          color: $scheme['light']['color']['medium']

    > .steps .disabled a,
    > .steps .disabled a:hover,
    > .steps .disabled a:active
      background: $scheme['light']['background']['transparent']
      color: $scheme['light']['color']['medium']
      cursor default
    > .steps .current a,
    > .steps .current a:hover,
    > .steps .current a:active
      background: $scheme['light']['background']['light']
      cursor default
    > .steps .done a,
    > .steps .done a:hover,
    > .steps .done a:active
      background: $scheme['light']['background']['lighter']
      color: $scheme['light']['color']['dark']

    > .steps .error a,
    > .steps .error a:hover,
    > .steps .error a:active
      background: $brand['danger']
      color #fff

    > .actions a,
    > .actions a:hover,
    > .actions a:active
      background: $scheme['light']['background']['medium']
      background: linear-gradient(to top right, $scheme['light']['background']['light'], $scheme['light']['background']['lighter'])
      display block
      text-decoration none
    > .actions .disabled a,
    > .actions .disabled a:hover,
    > .actions .disabled a:active
      cursor: default
      color: $scheme['light']['color']['medium']
      background: $scheme['light']['background']['light']
      border-right: 1px solid $scheme['light']['background']['lighter']




.tabcontrol
  & > .steps
    position relative
    display block
    width 100%
    & > ul
      position relative
      margin 6px 0 0 0
      top 1px
      z-index 1
      & > li
        float left
        margin 5px 2px 0 0
        padding 1px
        &:hover
          background #edecec
          border 1px solid #bbb
          padding 0
        &.current
          background #fff
          border 1px solid #bbb
          border-bottom 0 none
          padding 0 0 1px 0
          margin-top 0
          & > a
            padding 15px 30px 10px 30px
        & > a
          color #5f5f5f
          display inline-block
          border 0 none
          margin 0
          padding 10px 30px
          text-decoration none
          &:hover
            text-decoration

  & > .content
    position relative
    display inline-block
    width 100%
    height 35em
    overflow hidden
    padding-top 20px
    & > .body
      float left
      position absolute
      width 95%
      height 95%
      padding 2.5%
      ul
        list-style disc !important
        & > li
          display list-item
