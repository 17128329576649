@font-face
  font-family: 'Lytiga Pro'
  font-style: normal
  font-weight: 300
	src: url('../../fonts/lytiga-pro/LytigaPro-Light.eot')
	src: url('../../fonts/lytiga-pro/LytigaPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/lytiga-pro/LytigaPro-Light.woff2') format('woff2'),
		url('../../fonts/lytiga-pro/LytigaPro-Light.woff') format('woff'),
		url('../../fonts/lytiga-pro/LytigaPro-Light.ttf') format('truetype'),
		url('../../fonts/lytiga-pro/LytigaPro-Light.svg#LytigaPro-Light') format('svg')

.font-beau-sans-pro
  font-family: 'Lytiga Pro'
