.fc
  direction: ltr
  text-align: left


  button
    -moz-box-sizing: border-box
    -webkit-box-sizing: border-box
    box-sizing: border-box
    margin: 0
    height: 2.1em
    padding: 0 0.6em
    font-size: 1em
    text-transform: capitalize
    white-space: nowrap
    cursor: pointer
    &::-moz-focus-inner
      margin: 0
      padding: 0
    .fc-icon
      position: relative
      top: -0.05em
      margin: 0 0.2em
      vertical-align: middle
  .fc-button-group
    & > *
      float: left
      margin: 0 0 0 -1px
    & > :first-child
      margin-left: 0
  table
    width: 100%
    box-sizing: border-box
    table-layout: fixed
    border-collapse: collapse
    border-spacing: 0
    font-size: 1em
  th
    text-align: center
  td
    &.fc-today
      border-style: double
  .fc-row
    border-style: solid
    border-width: 0
  .fc-toolbar
    & > *
      & > *
        float: left
        margin-left: 0.75em
      & > :first-child
        margin-left: 0
  .fc-axis
    vertical-align: middle
    padding: 0 4px
    white-space: nowrap
  .fc-list-table
    table-layout: auto

.fc-rtl
  text-align: right
  .fc-basic-view
    .fc-day-top
      .fc-day-number
        float: left
      .fc-week-number
        float: right
        border-radius: 0 0 0 3px
  .fc-axis
    text-align: left
  .fc-time-grid
    .fc-event-container
      margin: 0 2px 0 2.5%
    .fc-now-indicator-arrow
      right: 0
      border-width: 5px 6px 5px 0
      border-top-color: transparent
      border-bottom-color: transparent
  .fc-list-view
    direction: rtl
  .fc-list-heading-main
    float: right
  .fc-list-heading-alt
    float: left
  .fc-list-item-marker
    padding-left: 0

body
  .fc
    font-size: 1em

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td
  border-color: $scheme.dark.background.lighter

.fc-unthemed
  .fc-popover
    background-color: $scheme.dark.background.dark
    border-width: 1px
    border-style: solid
    .fc-header
      background-color: $scheme.dark.background.medium!important
      .fc-close
        color: #666
        font-size: 0.9em
        margin-top: 2px
  td
    &.fc-today
      background: $scheme.dark.background.light
  .fc-list-empty
    background-color: $scheme.light.background.medium

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td
  background: $scheme.dark.background.medium

.fc-highlight
  background: $brand.info
  opacity: 0.3

.fc-bgevent
  background: $brand.success
  opacity: 0.3

.fc-nonbusiness
  background: $scheme.dark.background.default

.fc-icon
  display: inline-block
  height: 1em
  line-height: 1em
  font-size: 1em
  text-align: center
  overflow: hidden
  font-family: 'Courier New', Courier, monospace
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  &:after
    position: relative

.fc-icon-left-single-arrow
  &:after
    content: '\02039'
    font-weight: bold
    font-size: 200%
    top: -7%

.fc-icon-right-single-arrow
  &:after
    content: '\0203A'
    font-weight: bold
    font-size: 200%
    top: -7%

.fc-icon-left-double-arrow
  &:after
    content: '\000AB'
    font-size: 160%
    top: -7%

.fc-icon-right-double-arrow
  &:after
    content: '\000BB'
    font-size: 160%
    top: -7%

.fc-icon-left-triangle
  &:after
    content: '\25C4'
    font-size: 125%
    top: 3%

.fc-icon-right-triangle
  &:after
    content: '\25BA'
    font-size: 125%
    top: 3%

.fc-icon-down-triangle
  &:after
    content: '\25BC'
    font-size: 125%
    top: 2%

.fc-icon-x
  &:after
    content: '\000D7'
    font-size: 200%
    top: 6%

.fc-state-default
  border: 1px solid
  background: linear-gradient(to bottom, $scheme.dark.background.light, $scheme.dark.background.lighter)
  border-color: $scheme.dark.background.lighter
  color: $scheme.light.color.dark
  &.fc-corner-left
    border-top-left-radius: 2px
    border-bottom-left-radius: 2px
  &.fc-corner-right
    border-top-right-radius: 2px
    border-bottom-right-radius: 2px

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled
  color: $scheme.light.color.default
  background-color: $scheme.dark.background.lighter
  transition: all 0.5s ease

.fc-state-hover
  text-decoration: none

.fc-state-down,
.fc-state-active
  background-color: $scheme.dark.background.medium
  background-image: none
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05)

.fc-state-disabled
  cursor: default
  background-image: none
  opacity: 0.65
  box-shadow: none

.fc-button-group
  display: inline-block

.fc-popover
  position: absolute
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15)
  .fc-header
    padding: 2px 4px
    .fc-title
      margin: 0 2px
    .fc-close
      cursor: pointer
  & > .ui-widget-header
    & + .ui-widget-content
      border-top: 0

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close
  float: left

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close
  float: right

.fc-divider
  border-style: solid
  border-width: 1px

hr
  &.fc-divider
    height: 0
    margin: 0
    padding: 0 0 2px
    border-width: 1px 0

.fc-clear
  clear: both

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton
  position: absolute
  top: 0
  left: 0
  right: 0

.fc-bg
  bottom: 0
  table
    height: 100%

.fc th,
.fc td
  border-style: solid
  border-width: 1px
  padding: 0
  vertical-align: top

.fc a
  cursor: pointer

.fc-row
  position: relative
  table
    border-left: 0 hidden transparent
    border-right: 0 hidden transparent
    border-bottom: 0 hidden transparent
  &:first-child
    table
      border-top: 0 hidden transparent
  .fc-bg
    z-index: 1
  .fc-bgevent-skeleton
    z-index: 2
  .fc-highlight-skeleton
    z-index: 3
  .fc-content-skeleton
    position: relative
    z-index: 4
    padding-bottom: 2px
  .fc-helper-skeleton
    z-index: 5
  &.fc-rigid
    overflow: hidden
    .fc-content-skeleton
      position: absolute
      top: 0
      left: 0
      right: 0

.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton
  bottom: 0

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table
  height: 100%

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td
  border-color: transparent

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td
  background: none
  border-color: transparent
  border-bottom: 0

.fc-row .fc-content-skeleton tbody: td,
.fc-row .fc-helper-skeleton tbody: td
  border-top: 0

.fc-scroller
  -webkit-overflow-scrolling: touch

.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid
  position: relative
  width: 100%

.fc-event
  position: relative
  display: block
  font-size: 0.85em
  line-height: 1.3
  border-radius: 3px
  border-radius: 0
  border: 1px solid $scheme.dark.background.lighter
  font-weight: normal
  .fc-bg
    z-index: 1
    background: $scheme.light.background.light
    opacity: 0.25
  .fc-content
    position: relative
    z-index: 2
  .fc-resizer
    position: absolute
    z-index: 4
    display: none
  &.fc-selected
    z-index: 9999 !important
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2)
    .fc-resizer
      &:before
        content: ''
        position: absolute
        z-index: 9999
        top: 50%
        left: 50%
        width: 40px
        height: 40px
        margin-left: -20px
        margin-top: -20px
    &.fc-dragging
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3)

.fc-event,
.fc-event-dot
  background: $scheme.dark.background.medium
  background: linear-gradient(to right, $scheme.dark.background.medium, $scheme.dark.background.light)

.fc-event,
.fc-event:hover,
.ui-widget .fc-event
  color: #fff
  text-decoration: none

.fc-event,
.fc-event.fc-draggable
  cursor: pointer

.fc-not-allowed,
.fc-not-allowed .fc-event
  cursor: not-allowed

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer
  display: block

.fc-h-event
  &.fc-selected
    &:before
      content: ''
      position: absolute
      z-index: 3
      top: -10px
      bottom: -10px
      left: 0
      right: 0
    .fc-resizer
      border-radius: 4px
      border-width: 1px
      width: 6px
      height: 6px
      border-style: solid
      border-color: inherit
      background: $scheme.light.background.light
      top: 50%
      margin-top: -4px
  &.fc-allow-mouse-resize
    .fc-resizer
      width: 7px
      top: -1px
      bottom: -1px

.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end
  margin-left: 0
  border-left-width: 0
  padding-left: 1px
  border-top-left-radius: 0
  border-bottom-left-radius: 0

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start
  margin-right: 0
  border-right-width: 0
  padding-right: 1px
  border-top-right-radius: 0
  border-bottom-right-radius: 0

.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer
  cursor: w-resize
  left: -1px

.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer
  cursor: e-resize
  right: -1px

.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer
  margin-left: -4px

.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer
  margin-right: -4px

.fc-day-grid-event
  margin: 1px 2px 0
  padding: 0 1px
  &.fc-selected
    &:after
      content: ''
      position: absolute
      z-index: 1
      top: -1px
      right: -1px
      bottom: -1px
      left: -1px
      background: #000
      opacity: 0.25
  .fc-content
    white-space: nowrap
    overflow: hidden
  .fc-time
    font-weight: bold

tr
  &:first-child
    & > td
      & > .fc-day-grid-event
        margin-top: 2px

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer
  margin-left: -2px

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer
  margin-right: -2px

a
  &.fc-more
    margin: 1px 3px
    font-size: 0.85em
    cursor: pointer
    text-decoration: none
    &:hover
      text-decoration: underline

.fc-limited
  display: none

.fc-day-grid
  .fc-row
    z-index: 1

.fc-more-popover
  z-index: 2
  width: 220px
  .fc-event-container
    padding: 10px

.fc-now-indicator
  position: absolute
  border: none solid red

.fc-unselectable
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-touch-callout: none
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

.fc-toolbar
  padding: 1rem 1rem 0.75rem
  border: 1px solid $scheme.dark.background.lighter
  background: $scheme.dark.background.light
  background: linear-gradient(to bottom right, $scheme.dark.background.light, $scheme.dark.background.lighter)
  text-align: center
  &.fc-header-toolbar
    margin-bottom: 1em
  &.fc-footer-toolbar
    margin-top: 1em
  .fc-left
    float: left
  .fc-right
    float: right
  .fc-center
    display: inline-block
    text-align: center
  h2
    margin: 0
    font-size: 1.5rem
    margin-top: 0.4rem

  button
    position: relative
    &:focus
      z-index: 5
  .fc-state-down
    z-index: 3

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover
  z-index: 2

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active
  z-index: 4

.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after
  -webkit-box-sizing: content-box
  -moz-box-sizing: content-box
  box-sizing: content-box

.fc-view,
.fc-view > table
  position: relative
  z-index: 1

.fc-view
  border-radius: 2px
  background: $scheme.dark.background.lighter
  background: linear-gradient(to bottom right, $scheme.dark.background.light, $scheme.dark.background.lighter)


.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton
  padding-bottom: 1em

.fc-basic-view
  .fc-body
    .fc-row
      min-height: 4em
  .fc-day-top
    .fc-week-number
      min-width: 1.5em
      text-align: center
      background-color: #f2f2f2
      color: #808080
  td
    &.fc-week-number
      text-align: center
      & > *
        display: inline-block
        min-width: 1.25em

.fc-day-top
  &.fc-other-month
    opacity: 0.3

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number
  padding: 2px

.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number
  padding: 0 2px

.fc-ltr
  .fc-basic-view
    .fc-day-top
      .fc-day-number
        float: right
      .fc-week-number
        float: left
        border-radius: 0 0 3px 0
  .fc-axis
    text-align: right
  .fc-time-grid
    .fc-event-container
      margin: 0 2.5% 0 2px
    .fc-now-indicator-arrow
      left: 0
      border-width: 5px 0 5px 6px
      border-top-color: transparent
      border-bottom-color: transparent
  .fc-list-heading-main
    float: left
  .fc-list-heading-alt
    float: right
  .fc-list-item-marker
    padding-right: 0

.fc-agenda-view
  .fc-day-grid
    position: relative
    z-index: 2
    .fc-row
      min-height: 3em
      .fc-content-skeleton
        padding-bottom: 1em

.ui-widget
  td
    &.fc-axis
      font-weight: normal

.fc-time-grid-container,
.fc-time-grid
  position: relative
  z-index: 1

.fc-time-grid
  min-height: 100%
  table
    border: none hidden transparent
  & > .fc-bg
    z-index: 1
  .fc-content-col
    position: relative
  .fc-content-skeleton
    position: absolute
    z-index: 3
    top: 0
    left: 0
    right: 0
  .fc-business-container
    position: relative
    z-index: 1
  .fc-bgevent-container
    position: relative
    z-index: 2
  .fc-highlight-container
    position: relative
    z-index: 3
    position: relative
  .fc-event-container
    position: relative
    z-index: 4
  .fc-now-indicator-line
    z-index: 5
    border-top-width: 1px
    left: 0
    right: 0
  .fc-helper-container
    position: relative
    z-index: 6
  .fc-slats
    td
      height: 1.5em
      border-bottom: 0
    .fc-minor
      td
        border-top-style: dotted
    .ui-widget-content
      background: none
  .fc-highlight
    position: absolute
    left: 0
    right: 0
  .fc-bgevent
    left: 0
    right: 0
  .fc-now-indicator-arrow
    margin-top: -5px

.fc-time-grid .fc-slats,
.fc-time-grid > hr
  position: relative
  z-index: 2

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent
  position: absolute
  z-index: 1

.fc-v-event
  &.fc-not-start
    border-top-width: 0
    padding-top: 1px
    border-top-left-radius: 0
    border-top-right-radius: 0
  &.fc-not-end
    border-bottom-width: 0
    padding-bottom: 1px
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0

.fc-time-grid-event
  overflow: hidden
  &.fc-selected
    overflow: visible
    .fc-bg
      display: none
    .fc-resizer
      border-radius: 5px
      border-width: 1px
      width: 8px
      height: 8px
      border-style: solid
      border-color: inherit
      background: $scheme.light.background.medium
      left: 50%
      margin-left: -5px
      bottom: -5px
  .fc-content
    overflow: hidden
  .fc-time
    font-size: 0.85em
    white-space: nowrap
  &.fc-short
    .fc-content
      white-space: nowrap
    .fc-time
      span
        display: none
      &:before
        content: attr(data-start)
      &:after
        content: '\000A0-\000A0'
    .fc-title
      font-size: 0.85em
      padding: 0
  &.fc-allow-mouse-resize
    .fc-resizer
      left: 0
      right: 0
      bottom: 0
      height: 8px
      overflow: hidden
      line-height: 8px
      font-size: 11px
      font-family: monospace
      text-align: center
      cursor: s-resize
      
      &:after
        content: '='

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title
  padding: 0 1px

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title
  display: inline-block
  vertical-align: top

.fc-event-dot
  display: inline-block
  width: 10px
  height: 10px
  border-radius: 5px

.fc-list-view
  border-width: 1px
  border-style: solid

.fc-list-table
  td
    border-width: 1px 0 0
    padding: 8px 14px
  tr
    &:first-child
      td
        border-top-width: 0

.fc-list-heading
  border-bottom-width: 1px
  td
    font-weight: bold

.fc-list-item
  &.fc-has-url
    cursor: pointer
  &:hover
    td
      background-color: $scheme.light.background.medium

.fc-list-item-marker,
.fc-list-item-time
  white-space: nowrap
  width: 1px

.fc-list-item-title
  a
    text-decoration: none
    color: inherit
  a:hover
    text-decoration: underline

.fc-list-empty-wrap2
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

.fc-list-empty-wrap1
  width: 100%
  height: 100%
  display: table

.fc-list-empty
  display: table-cell
  vertical-align: middle
  text-align: center

#external-events .fc-event
  margin-bottom: 0.5rem
  padding: 0.25rem


+breakpoint-down(xs)
  .fc
    .fc-toolbar
      clearfix()

      .fc-left
        .fc-button-group
          float: left
          width: percentage(2/3)
          > button
            width: percentage(1/2)
        .fc-today-button
          margin: 0
          float: left
          width: percentage(1/3)
      .fc-right
        .fc-button-group
          width: 100%
          float: none

          > button
            width: percentage(1/3)

      .fc-left
      .fc-right
        clearfix()
        margin-bottom: 1rem
        display: block
        position: relative
        width: 100%
        float: none
        box-sizing: border-box

        > *
          float: none


  #calendar-add-event
    display: block
    position: relative;
    top: auto
    right: auto
    margin-top: 1rem
