.error-section
  margin: 4rem auto
  text-align: center

  .error-content
    display: block
    z-index: 1
    position: relative

  .error-title
    font-weight: 100
    font-size: 5rem
    text-transform: uppercase
    display: block
    position: relative
    margin: 0 auto

  .error-subtitle
    font-weight: 300
    font-size: 1.5rem
    opacity: 0.5

  .error-icon
    display: block
    position: absolute
    left: 50%
    bottom: -5rem
    font-size: 20rem
    opacity: 0.25
    z-index: 0

  .btn
    z-index: 1
    margin-top: 3rem
