img
  &.-responsive
    max-width: 100%
    height: auto

  &.-bordered
    &.-dark
      border: 10px solid $scheme['dark']['background']['light']
    &.-light
      border: 10px solid $scheme['light']['background']['light']

  &.-rounded
    border-radius: 5px

  &.-round
    border-radius: 100%

  &.-fluid
    width: 100%
    height: auto
