/*
  Application

  Table of Contents
  ---
  1. Fonts
  2. Core Styles
    2.1 Colors
    2.2 Box Model
    2.3 Layout
    2.4 Scaffolding
    2.5 Themes
    2.6 Typography
  3. Components
    3.1 Alert
    3.2 Breadcrumb
    3.3 Button
    3.4 Calendar
    3.5 Chart
    3.6 Datepicker
    3.7 Dropdown
    3.8 Error
    3.9 Form
    3.10 Image
    3.11 List
    3.12 Mail
    3.13 Maps
    3.14 Morph Dropdown
    3.15 Modal
    3.16 Page Heading
    3.17 Pagination
    3.18 Panel
    3.19 Popover
    3.20 Progress
    3.21 Navbar
    3.22 Notifications
    3.23 Sidebar
    3.24 Steps
    3.25 Table
    3.26 Tabs
    3.27 Tag
    3.28 WYSIWYG
*/

/*
  Default Theme Colors
*/
$primary ?= #C04848
$secondary ?= #480048

/*
  0. Configuration

  @visible Stylus only
*/
@import '../config/_breakpoints'
@import '../config/_colors'
@import '../config/_variables'
@import '../config/_mixins'


/*
  1. Fonts

  @uses @font-face

  @import 'fonts/raleway'
  @import 'fonts/montserrat'
  @import '../fonts/kg-blank-space'
  @import '../fonts/dancing-script'
  @import '../fonts/pacifico'
*/
@import '../fonts/_proxima-nova'
@import '../fonts/_lytiga-pro'


/*
  2. Core
*/

/*
  2.1 Colors
*/
@import '../core/_colors'

/*
  2.2 Box Model
*/
@import '../core/_box-model'

/*
  2.3 Layout
*/
@import '../core/_layout'

/*
  2.4 Scaffolding
*/
@import '../core/_scaffolding'

/*
  2.5 Themes
*/
@import '../core/_themes'

/*
  2.6 Typography
*/
@import '../core/_typography'


/*
  3. Components
*/

/*
  3.1 Alert
*/
@import '../components/_alert'

/*
  3.2 Breadcrumb
*/
@import '../components/_breadcrumb'

/*
  3.3 Button
*/
@import '../components/_button'

/*
  3.4 Calendar
*/
@import '../components/_calendar'

/*
  3.5 Chart
*/
@import '../components/_chart'

/*
  3.6 Datepicker
*/
@import '../components/_datepicker'

/*
  3.7 Dropdown
*/
@import '../components/_dropdown'

/*
  3.8 Error
*/
@import '../components/_error'

/*
  3.9 Form
*/
@import '../components/_form'

/*
  3.10 Image
*/
@import '../components/_image'

/*
  3.11 List
*/
@import '../components/_list'

/*
  3.12 Mail
*/
@import '../components/_mail'

/*
  3.13 Maps
*/
@import '../components/_maps'

/*
  3.14 Morph Dropdown
*/
@import '../components/_morph-dropdown'

/*
  3.15 Modal
*/
@import '../components/_modal'

/*
  3.16 Page Heading
*/
@import '../components/_page-heading'

/*
  3.17 Pagination
*/
@import '../components/_pagination'

/*
  3.18 Panel
*/
@import '../components/_panel'

/*
  3.19 Popover
*/
@import '../components/_popover'

/*
  3.20 Progress
*/
@import '../components/_progress'

/*
  3.21 Navbar
*/
@import '../components/_navbar'

/*
  3.22 Notifications
*/
@import '../components/_notifications'

/*
  3.23 Sidebar
*/
@import '../components/_sidebar'

/*
  3.24 Steps
*/
@import '../components/_steps'

/*
  3.25 Table
*/
@import '../components/_table'

/*
  3.26 Tabs
*/
@import '../components/_tabs'

/*
  3.27 Tag
*/
@import '../components/_tag'

/*
  3.28 WYSIWYG
*/
@import '../components/_wysiwyg'

/*
  4.0 Themes
*/
@import '../themes/_core'
