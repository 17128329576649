[data-change-theme='volta']
  background-image: linear-gradient(to right, #C04848, #480048)
[data-change-theme='purple-bliss']
  background-image: linear-gradient(to right, #360033, #0b8793)
[data-change-theme='love-couple']
  background-image: linear-gradient(to right, #89253e, #3a6186)
[data-change-theme='deep-space']
  background-image: linear-gradient(to right, #434343, #000000)
[data-change-theme='from-beyond']
  background-image: linear-gradient(to right, #838181, #000C40)
[data-change-theme='curiosity-blue']
  background-image: linear-gradient(to right, #3d72b4, #525252)
[data-change-theme='orca']
  background-image: linear-gradient(to right, #093637, #44a08d)
[data-change-theme='love-tonight']
  background-image: linear-gradient(to right, #4568dc, #b06ab3)
[data-change-theme='love-and-liberty']
  background-image: linear-gradient(to right, #a83b3b, #200122)
[data-change-theme='blue-lagoon']
  background-image: linear-gradient(to right, #43C6AC, #191654)
[data-change-theme='grapefruit-sunset']
  background-image: linear-gradient(to right, #904e95, #e96443)
[data-change-theme='frost']
  background-image: linear-gradient(to right, #004e92, #000428)
[data-change-theme='mauve']
  background-image: linear-gradient(to right, #734b6d, #42275a)
[data-change-theme='under-the-lake']
  background-image: linear-gradient(to right, #237a57, #093028)
[data-change-theme='vice-city']
  background-image: linear-gradient(to right, #ec6ead, #3494e6)
[data-change-theme='mild']
  background-image: linear-gradient(to right, #4ca2cd, #67b26f)
[data-change-theme='nepal']
  background-image: linear-gradient(to right, #2657eb, #de6161)
[data-change-theme='ibiza-sunset']
  background-image: linear-gradient(to right, #ff6a00, #ee0979)
[data-change-theme='sunset']
  background-image: linear-gradient(to right, #f56217, #0b486b)
[data-change-theme='colored-lenses']
  background-image: linear-gradient(to right, #636fa4, #e8cbc0)
[data-change-theme='disco']
  background-image: linear-gradient(to right, #556270, #4ecdc4)
[data-change-theme='dania']
  background-image: linear-gradient(to right, #7bc6cc, #be93c5)
[data-change-theme='50shades']
  background-image: linear-gradient(to right, #2c3e50, #bdc3c7)
[data-change-theme='very-blue']
  background-image: linear-gradient(to right, #0575e6, #021b79)
[data-change-theme='dawn']
  background-image: linear-gradient(to right, #F3904F, #3B4371)
[data-change-theme='dusk']
  background-image: linear-gradient(to right, #19547b, #ffd89b)
[data-change-theme='dusk-2']
  background-image: linear-gradient(to right, #fd746c, #2c3e50)
[data-change-theme='delhi']
  background-image: linear-gradient(to right, #3fada8, #808080)
[data-change-theme='cosmic-fusion']
  background-image: linear-gradient(to right, #ff00cc, #333399)
[data-change-theme='firewatch']
  background-image: linear-gradient(to right, #ef473a, #cb2d3e)
[data-change-theme='lush']
  background-image: linear-gradient(to right, #56ab2f, #a8e063)
[data-change-theme='80s-purple']
  background-image: linear-gradient(to right, #41295a, #2F0743)
[data-change-theme='royal']
  background-image: linear-gradient(to right, #243b55, #141e30)
[data-change-theme='deep-sea-space']
  background-image: linear-gradient(to right, #4ca1af, #2c3e50)
[data-change-theme='solid-vault']
  background-image: linear-gradient(to right, #3a6073, #3a7bd5)
[data-change-theme='politics']
  background-image: linear-gradient(to right, #f44336, #2196f3)
[data-change-theme='transfile']
  background-image: linear-gradient(to right, #cb3066, #16bffd)
[data-change-theme='red-ocean']
  background-image: linear-gradient(to right, #a43931, #1d4350)
[data-change-theme='pink-lime']
  background-image: linear-gradient(to right, #a80077, #66ff00)
[data-change-theme='easy-med']
  background-image: linear-gradient(to right, #409c43, #c2c93a)
[data-change-theme='cocoa-ice']
  background-image: linear-gradient(to right, #1ce1ce, #c0c0aa)
[data-change-theme='candy-ice']
  background-image: linear-gradient(to right, #db36a4, #f7ff00)
[data-change-theme='dark-skies']
  background-image: linear-gradient(to right, #283e51, #4b79a1)
[data-change-theme='forest']
  background-image: linear-gradient(to right, #2c7744, #5a3f37)
[data-change-theme='miami-dolphins']
  background-image: linear-gradient(to right, #d39d38, #4da0b0)
