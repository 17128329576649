
// @font-face
//   font-family: 'Proxima Nova Cn Rg'
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-RegularIt.eot')
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-RegularIt.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNovaCond-RegularIt.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNovaCond-RegularIt.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNovaCond-RegularIt.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNovaCond-RegularIt.svg#ProximaNovaCond-RegularIt') format("svg")
//   font-weight: normal
//   font-style: italic


// @font-face
//   font-family: 'Proxima Nova Lt'
//   src: url('../../fonts/proxima-nova/ProximaNova-SemiboldIt.eot')
//   src: url('../../fonts/proxima-nova/ProximaNova-SemiboldIt.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-SemiboldIt.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-SemiboldIt.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-SemiboldIt.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-SemiboldIt.svg#ProximaNova-SemiboldIt') format("svg")
//   font-weight: 600
//   font-style: italic


// @font-face
//   font-family: 'Proxima Nova Cn Lt'
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-Light.eot')
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-Light.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNovaCond-Light.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNovaCond-Light.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNovaCond-Light.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNovaCond-Light.svg#ProximaNovaCond-Light') format("svg")
//   font-weight: 300
//   font-style: normal


// @font-face
//   font-family: 'Proxima Nova Cn Rg'
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-Regular.eot')
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-Regular.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNovaCond-Regular.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNovaCond-Regular.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNovaCond-Regular.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNovaCond-Regular.svg#ProximaNovaCond-Regular') format("svg")
//   font-weight: normal
//   font-style: normal
//
//
// @font-face
//   font-family: 'Proxima Nova Cn Lt'
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-Semibold.eot')
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-Semibold.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNovaCond-Semibold.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNovaCond-Semibold.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNovaCond-Semibold.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNovaCond-Semibold.svg#ProximaNovaCond-Semibold') format("svg")
//   font-weight: 600
//   font-style: normal


// @font-face
//   font-family: 'Proxima Nova Rg'
//   src: url('../../fonts/proxima-nova/ProximaNova-RegularIt.eot')
//   src: url('../../fonts/proxima-nova/ProximaNova-RegularIt.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-RegularIt.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-RegularIt.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-RegularIt.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-RegularIt.svg#ProximaNova-RegularIt') format("svg")
//   font-weight: normal
//   font-style: italic


// @font-face
//   font-family: 'Proxima Nova'
//   src: url('../../fonts/proxima-nova/ProximaNova-Black.eot')
//   src: url('../../fonts/proxima-nova/ProximaNova-Black.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-Black.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-Black.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-Black.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-Black.svg#ProximaNova-Black') format("svg")
//   font-weight: 900
//   font-style: normal

@font-face
  font-family: 'Proxima Nova'
  font-style: normal
  font-weight: 800
  src: url('../../fonts/proxima-nova/ProximaNova-Extrabld.eot')
  src: url('../../fonts/proxima-nova/ProximaNova-Extrabld.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-Extrabld.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-Extrabld.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-Extrabld.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format("svg")


@font-face
  font-family: 'Proxima Nova'
  font-style: normal
  font-weight: 600
  src: url('../../fonts/proxima-nova/ProximaNova-Semibold.eot')
  src: url('../../fonts/proxima-nova/ProximaNova-Semibold.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-Semibold.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-Semibold.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-Semibold.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-Semibold.svg#ProximaNova-Semibold') format("svg")

@font-face
  font-family: 'Proxima Nova'
  font-style: normal
  font-weight: bold
  src: url('../../fonts/proxima-nova/ProximaNova-Bold.eot')
  src: url('../../fonts/proxima-nova/ProximaNova-Bold.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-Bold.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-Bold.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-Bold.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-Bold.svg#ProximaNova-Bold') format("svg")

@font-face
  font-family: 'Proxima Nova'
  font-style: normal
  font-weight: normal
  src: url('../../fonts/proxima-nova/ProximaNova-Regular.eot')
  src: url('../../fonts/proxima-nova/ProximaNova-Regular.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-Regular.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-Regular.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-Regular.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-Regular.svg#ProximaNova-Regular') format("svg")

@font-face
  font-family: 'Proxima Nova'
  font-style: normal
  font-weight: 300
  src: url('../../fonts/proxima-nova/ProximaNova-Light.eot')
  src: url('../../fonts/proxima-nova/ProximaNova-Light.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-Light.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-Light.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-Light.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-Light.svg#ProximaNova-Light') format("svg")

@font-face
  font-family: 'Proxima Nova'
  font-style: normal
  font-weight: 100
  src: url('../../fonts/proxima-nova/ProximaNova-Thin.eot')
  src: url('../../fonts/proxima-nova/ProximaNova-Thin.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-Thin.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-Thin.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-Thin.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-Thin.svg#ProximaNova-Thin') format("svg")

.font-proxima-nova
  font-family: 'Proxima Nova'

// @font-face
//   font-family: 'Proxima Nova Cn Lt'
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-LightIt.eot')
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-LightIt.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNovaCond-LightIt.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNovaCond-LightIt.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNovaCond-LightIt.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNovaCond-LightIt.svg#ProximaNovaCond-LightIt') format("svg")
//   font-weight: 300
//   font-style: italic





// @font-face
//   font-family: 'Proxima Nova Rg'
//   src: url('../../fonts/proxima-nova/ProximaNova-BoldIt.eot')
//   src: url('../../fonts/proxima-nova/ProximaNova-BoldIt.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-BoldIt.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-BoldIt.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-BoldIt.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-BoldIt.svg#ProximaNova-BoldIt') format("svg")
//   font-weight: bold
//   font-style: italic





// @font-face
//   font-family: 'Proxima Nova Rg'
//   src: url('../../fonts/proxima-nova/ProximaNova-RegularIt_1.eot')
//   src: url('../../fonts/proxima-nova/ProximaNova-RegularIt_1.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-RegularIt_1.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-RegularIt_1.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-RegularIt_1.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-RegularIt_1.svg#ProximaNova-RegularIt') format("svg")
//   font-weight: normal
//   font-style: italic


// @font-face
//   font-family: 'Proxima Nova Lt'
//   src: url('../../fonts/proxima-nova/ProximaNova-LightIt.eot')
//   src: url('../../fonts/proxima-nova/ProximaNova-LightIt.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNova-LightIt.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNova-LightIt.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNova-LightIt.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNova-LightIt.svg#ProximaNova-LightIt') format("svg")
//   font-weight: 300
//   font-style: italic




// @font-face
//   font-family: 'Proxima Nova Cn Lt'
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-SemiboldIt.eot')
//   src: url('../../fonts/proxima-nova/ProximaNovaCond-SemiboldIt.eot?#iefix') format("embedded-opentype"), url('../../fonts/proxima-nova/ProximaNovaCond-SemiboldIt.woff2') format("woff2"), url('../../fonts/proxima-nova/ProximaNovaCond-SemiboldIt.woff') format("woff"), url('../../fonts/proxima-nova/ProximaNovaCond-SemiboldIt.ttf') format("truetype"), url('../../fonts/proxima-nova/ProximaNovaCond-SemiboldIt.svg#ProximaNovaCond-SemiboldIt') format("svg")
//   font-weight: 600
//   font-style: italic
//
//
